import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import styled from 'styled-components';
import { gray100 } from '../constants';

export type PageLoaderProps = {
  loadingText?: string;
};

const StyledDiv = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  background: ${gray100};
`;

const StyledText = styled.span`
  font-size: 2rem;
`;

export const PageLoader: React.FC<PageLoaderProps> = ({ loadingText }) => (
  <StyledDiv>
    <ProgressSpinner />
    {loadingText && <StyledText>{loadingText}</StyledText>}
  </StyledDiv>
);
