import { borderRadius, gray200 } from '@/components';
import React from 'react';
import styled, { css } from 'styled-components';

type PanelProps = {
  fullWidth?: boolean;
  alignItems?: string;
  justifyContent?: string;
  [s: string]: any;
};

const StyledDiv = styled.div<PanelProps>`
  background: ${gray200};
  width: 100%;
  border-radius: ${borderRadius}px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
`;

export const Panel: React.FC<PanelProps> = ({
  children,
  fullWidth,
  justifyContent,
  ...rest
}) => (
  <StyledDiv fullWidth={fullWidth} justifyContent={justifyContent} {...rest}>
    {children}
  </StyledDiv>
);
