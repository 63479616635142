import React from 'react';
import styled from 'styled-components';
import LogoSvg from '../../../svg/logo.svg';
import { Link } from 'react-router-dom';
import { gray700 } from '../../constants';

const StyledHeader = styled.header`
  display: flex;
  padding: 20px;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: 20px;
  margin-right: 20px;
`;

const StyledTitle = styled.h2`
  font-weight: 500;
  font-size: 19px;
  text-transform: uppercase;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${gray700};
  text-decoration: none;
`;

export const Header: React.FC = () => (
  <StyledHeader>
    <StyledLogoContainer>
      <StyledLink to={'/'}>
        <Logo src={LogoSvg} alt="Timeshifter" />
        <StyledTitle>Timeshifter admin</StyledTitle>
      </StyledLink>
    </StyledLogoContainer>
  </StyledHeader>
);
