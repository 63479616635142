import { TimeshifterSDK } from '@timeshifter/react-sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './assets/index.scss';
import reportWebVitals from './reportWebVitals';
import Root from './Root';
import { store } from './store';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <TimeshifterSDK
        apiUrl={process.env.REACT_APP_API_URL as string}
        onUnathorized={(url) => {
          if (url === '/signin') {
            return;
          }

          window.location.href = '/unauthorized';
        }}
      >
        <Provider store={store}>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </Provider>
      </TimeshifterSDK>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
