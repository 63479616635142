import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

type FormRowProps = {
  label?: string;
  error?: string;
  elementId?: string;
  requried?: boolean;
  helpText?: string;
  block?: boolean;
};

const HelpText = styled.small`
  margin-top: 5px;
`;

const RedSup = styled.sup`
  color: red;
`;

export const FormRow: React.FC<FormRowProps> = ({
  children,
  label,
  error,
  requried = false,
  elementId,
  helpText,
  block,
}) => (
  <div
    className={classNames({
      'form-row': true,
      'form-row--block': block,
    })}
  >
    {label && (
      <label htmlFor={elementId} className="form-row__label">
        {label} {requried && <RedSup>*</RedSup>}
      </label>
    )}
    <div className="form-row__field">
      {children}
      {helpText && <HelpText className="block muted">{helpText}</HelpText>}
      {error && <span className="p-error block">{error}</span>}
    </div>
  </div>
);
