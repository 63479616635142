import { ValidationError } from '@timeshifter/react-sdk';
import React from 'react';
import styled from 'styled-components';
import { Message } from 'primereact/message';

const ErrorContainer = styled.div`
  margin-top: 20px;
`;

const ErrorMessage = styled(Message)`
  width: 100%;
  text-align: left;
  justify-content: flex-start;
`;

type ValidationErrorsProps = {
  errors: ValidationError[];
};

const getErrors = (errors: ValidationError[]): string[] =>
  errors.reduce((a, e) => {
    if (e.children && e.children.length > 0) {
      return [...a, ...getErrors(e.children)];
    }

    return [...a, ...e.errors];
  }, [] as string[]);

export const ValidationErrors: React.FC<ValidationErrorsProps> = ({
  errors,
}) => (
  <ErrorContainer>
    {errors.length === 0 && (
      <ErrorMessage
        severity="error"
        text={'An API server error occurred. Please, try again later.'}
      />
    )}
    {getErrors(errors)?.map((e, index) => (
      <ErrorMessage severity="error" text={e} key={index + 1} />
    ))}
  </ErrorContainer>
);
