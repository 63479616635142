import { borderRadius, green400, green500 } from '@/components';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { useRef } from 'react';
import styled from 'styled-components';

const StyledAddButton = styled(Button)`
  padding: 0;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const items: MenuItem[] = [
  {
    label: 'Add rule',
    url: '/rules/create',
  },
  {
    label: 'Add user',
    url: '/users/create',
  },
  {
    label: 'Add invite',
    url: '/invites/create',
  },
  {
    label: 'Add subscription plan',
    url: '/subscription-plans/create',
  },
  {
    label: 'Add company',
    url: '/companies/create',
  },
];

const StyledMenuContainer = styled.div`
  position: relative;
`;

const StyledMenu = styled(Menu)`
  top: 0px !important;
  left: 50px !important;
  background: ${green400} !important;
  padding: 0;
  border-radius: ${borderRadius}px;

  &.p-menu .p-menuitem-link,
  &.p-menu .p-menuitem-link span,
  &.p-menu .p-menuitem-link:hover:not(.p-disabled) span {
    color: #fff;
  }

  &.p-menu .p-menuitem-link {
    transition: background-color 300ms linear;
  }

  &.p-menu .p-menuitem-link:hover {
    background: ${green500};
    color: #fff;
  }

  &.p-menu .p-menuitem:first-child .p-menuitem-link,
  &.p-menu .p-menuitem:first-child .p-menuitem-link:hover {
    border-radius: ${borderRadius}px ${borderRadius}px 0 0;
  }

  &.p-menu .p-menuitem:last-child .p-menuitem-link,
  &.p-menu .p-menuitem:last-child .p-menuitem-link:hover {
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
  }
`;

export const AddItemsButton: React.FC = () => {
  const menuRef = useRef<Menu>(null);
  const menuContainerRef = useRef<HTMLDivElement>(null);

  return (
    <StyledMenuContainer ref={menuContainerRef}>
      <StyledMenu popup ref={menuRef} model={items} appendTo="self" />
      <StyledAddButton
        className="p-button-rounded"
        onClick={(event) => menuRef.current?.toggle(event)}
      >
        <i className="pi pi-plus"></i>
      </StyledAddButton>
    </StyledMenuContainer>
  );
};
