import React, { Suspense } from 'react';
import { PageLoader } from './components';

const App = React.lazy(() => import('./App'));

const Root: React.FC = () => (
  <Suspense fallback={<PageLoader loadingText="Loading ..." />}>
    <App />
  </Suspense>
);

export default Root;
