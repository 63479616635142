import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';

export type FormikInputProps = {
  field: { name: string; value: any; onChange: () => void; onBlur: () => void };
  form: { touched: Record<string, any>; errors: Record<string, any> };
};

export const FormikInput: React.FC<FormikInputProps> = ({
  field,
  form,
  ...rest
}) => (
  <InputText
    {...field}
    value={field.value ?? ''}
    {...rest}
    className={classNames({
      'p-invalid': !!form.errors[field.name],
    })}
  />
);
