import moment from 'moment';
import React from 'react';

type FormatDateProps = {
  date: string | Date | undefined;
  format?: string;
};

export const FormatDate: React.FC<FormatDateProps> = ({
  date,
  format = 'DD.MM.YYYY HH:mm',
}) => {
  if (!date) {
    return null;
  }

  return <>{moment(date).format(format)}</>;
};
