import { InputText } from 'primereact/inputtext';
import React, { ChangeEvent, useState } from 'react';

type BetweenFilterProps = {
  placeholder?: string[];
  value?: { from?: any; to?: any };
  field: string;
  onChange: (value: any, field: string) => void;
};

export const BetweenFilter: React.FC<BetweenFilterProps> = ({
  field,
  placeholder,
  onChange,
  value,
}) => {
  const [values, setValues] = useState<{ from: string; to: string }>({
    from: value && value.from ? value.from : '',
    to: value && value.to ? value.to : '',
  });

  const onInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: 'from' | 'to',
  ) => {
    const nextState = {
      ...values,
      [type]: event.currentTarget.value,
    };
    setValues(nextState);
    onChange(
      {
        value: nextState,
      },
      field,
    );
  };
  const [fromId, toId] = [`from-${field}`, `to-${field}`];
  return (
    <div className="flex align-items-center">
      <InputText
        id={fromId}
        defaultValue={value && value.from ? value.from : undefined}
        placeholder={placeholder && placeholder[0] ? placeholder[0] : undefined}
        onChange={(event) => onInputChange(event, 'from')}
      />
      <span className="mx-2">to</span>
      <InputText
        id={toId}
        defaultValue={value && value.to ? value.to : undefined}
        placeholder={placeholder && placeholder[1] ? placeholder[1] : undefined}
        onChange={(event) => onInputChange(event, 'to')}
      />
    </div>
  );
};
