import { useField } from 'formik';
import React, { useState } from 'react';
import { like, useCompanies } from '@timeshifter/react-sdk';
import Select, { SingleValue } from 'react-select';

type CompaniesAutocompleteInputProps = {
  name: string;
  selectedCompany?: string;
  placeholder?: string;
};

export const CompaniesAutocompleteInput: React.FC<
  CompaniesAutocompleteInputProps
> = ({ name, selectedCompany, placeholder }) => {
  const [field, meta, helpers] = useField({
    name,
  });

  const [search, setSearch] = useState<string>('');

  const { companies } = useCompanies({
    filters: [like('name', search)],
  });

  return (
    <Select
      className="companies-autocomplete react-select"
      classNamePrefix="react-select"
      placeholder={placeholder}
      defaultInputValue={selectedCompany}
      options={companies?.map((c) => ({ label: c.name, value: c.id })) as any}
      onInputChange={(e) => setSearch(e)}
      onChange={(e: SingleValue<{ value: string }>) =>
        helpers.setValue(e?.value)
      }
    />
  );
};
