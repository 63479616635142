import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import styled from 'styled-components';
import { Filter } from './Filter';
import { FilterType } from './types';

type FiltersProps = {
  filters: FilterType[];
  onFilter: (filters: Record<string, any>) => void;
  defaultValues?: Record<string, any>;
};

const StyledFilters = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const StyledFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledIcon = styled.i`
  margin-right: 10px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
  flex-shrink: 0;
`;

const filtersReducer = (
  state: Record<string, { value: any; filter: FilterType }>,
  action: {
    type: 'updateValue' | 'clear' | 'remove' | 'add';
    key: string;
    value?: any;
    filter?: FilterType;
  },
): Record<string, { value: any; filter: FilterType }> => {
  switch (action.type) {
    case 'clear':
      return {};
    case 'remove':
      delete state[action.key];

      return { ...state };
    case 'add':
      return {
        ...state,
        [action.key]: {
          value: action.filter?.defaultValue ?? null,
          filter: action.filter as FilterType,
        },
      };
    case 'updateValue':
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          value: action.value,
        },
      };
    default:
      return state;
  }
};

const buildFiltersFromValues = (
  filters: FilterType[],
  values: Record<string, any> | undefined,
): Record<string, { value: any; filter: FilterType }> => {
  if (!values) {
    return {};
  }

  return Object.keys(values).reduce((a, k) => {
    if (!values[k]) {
      return a;
    }

    const filter = filters.find((f) => f.field === k);

    if (!filter) {
      return a;
    }

    return {
      ...a,
      [k]: { value: values[k], filter },
    };
  }, {});
};

export const Filters: React.FC<FiltersProps> = ({
  filters,
  onFilter,
  defaultValues,
}) => {
  const [values, dispatch] = useReducer(
    filtersReducer,
    buildFiltersFromValues(filters, defaultValues),
  );

  const menuRef = useRef<Menu>(null);
  const items: MenuItem[] = useMemo(
    () =>
      filters.map(
        (f): MenuItem => ({
          label: f.label,
          disabled: !!values[f.field],
          command: () => dispatch({ type: 'add', filter: f, key: f.field }),
        }),
      ),
    [JSON.stringify(filters), JSON.stringify(values)],
  );

  const onChange = (value: any, field: string) => {
    dispatch({
      type: 'updateValue',
      value,
      key: field,
    });
  };

  const onRemove = (field: string) => {
    dispatch({
      type: 'remove',
      key: field,
    });
  };

  useEffect(() => {
    onFilter(
      Object.values(values).reduce(
        (a, v) => ({ ...a, [v.filter.field]: v.value }),
        {},
      ),
    );
  }, [JSON.stringify(values)]);

  return (
    <StyledFilters>
      <Menu model={items} popup ref={menuRef} />

      <StyledFiltersContainer>
        {Object.keys(values).map((f: string) => (
          <Filter
            {...values[f]}
            onChange={onChange}
            key={f}
            onRemove={onRemove}
          />
        ))}
      </StyledFiltersContainer>

      <StyledButton
        className="p-button-outlined p-button--orange p-button-sm"
        onClick={(event) => menuRef.current?.toggle(event)}
        label="+ Add filter"
      />
    </StyledFilters>
  );
};
