import React from 'react';
import styled from 'styled-components';

const StyledMain = styled.main`
  margin-right: 30px;
  width: 100%;
`;

export const Main: React.FC = ({ children }) => (
  <StyledMain>{children}</StyledMain>
);
