import React from 'react';
import { Link as RoutedLink, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { green500 } from '@/components';

const StyledLink = styled(RoutedLink)`
  color: ${green500};
  text-decoration: none;

  &:hover,
  &:focus,
  &:visited {
    text-decoration: none;
    color: ${green500};
  }
`;

export const Link: React.FC<LinkProps> = ({ children, ...rest }) => (
  <StyledLink {...rest}>{children}</StyledLink>
);
