import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { BetweenFilter } from './BetweenFilter';
import { InputFilter } from './InputFilter';
import { FilterType } from './types';

type FilterProps = {
  filter: FilterType;
  value: any;
  onChange: (event: ChangeEvent | DropdownChangeParams, field: string) => void;
  onRemove: (field: string) => void;
};

const StyledFilter = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Filter: React.FC<FilterProps> = ({
  filter,
  value,
  onChange,
  onRemove,
}) => (
  <StyledFilter>
    {filter.type === 'text' && (
      <InputFilter
        {...filter}
        onChange={onChange}
        value={value}
        placeholder={filter.placeholder as string}
      />
    )}
    {filter.type === 'select' && (
      <Dropdown
        options={filter.options}
        placeholder={filter.placeholder as string}
        value={value}
        onChange={(event) => onChange(event.value, filter.field)}
      />
    )}
    {filter.type === 'between' && (
      <BetweenFilter
        {...filter}
        placeholder={filter.placeholder as string[]}
        value={value}
        onChange={(event) => onChange(event.value, filter.field)}
      />
    )}
    {filter.type === 'multi-select' && (
      <MultiSelect
        {...filter}
        placeholder={filter.placeholder as string}
        value={value}
        onChange={(event) => onChange(event.value, filter.field)}
      />
    )}

    <Button
      icon="pi pi-times"
      className="p-button-danger p-button-text"
      onClick={() => onRemove(filter.field)}
    />
  </StyledFilter>
);
