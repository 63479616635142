import React from 'react';
import styled from 'styled-components';

const StyledH2 = styled.h2`
  font-weight: 400;
  font-size: 19px;
  display: inline-block;
  margin: 0;
`;

type PageTitleProps = {
  title: string;
};

export const PageTitle: React.FC<PageTitleProps> = ({ title }) => (
  <StyledH2>{title}</StyledH2>
);
