import { debounce } from 'lodash';
import { InputText } from 'primereact/inputtext';
import React from 'react';

type InputFilterProps = {
  placeholder?: string;
  value?: string;
  field: string;
  onChange: (value: any, field: string) => void;
};

export const InputFilter: React.FC<InputFilterProps> = ({
  placeholder,
  value,
  onChange,
  field,
  ...rest
}) => {
  const debounedOnChange = debounce(onChange, 300);

  return (
    <InputText
      defaultValue={value}
      placeholder={placeholder}
      onChange={(event) => debounedOnChange(event.target.value, field)}
      {...rest}
    />
  );
};
