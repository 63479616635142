import { useCurrentUser, User } from '@timeshifter/react-sdk';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { PageLoader } from './PageLoader';

type ProtectedRouteProps = {
  canOpen?: (user: User) => boolean;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ canOpen }) => {
  const { isLoading, isFetched, user, isLoadingError } = useCurrentUser();

  if (isLoading && !isLoadingError) {
    return <PageLoader loadingText="Loading user data ..." />;
  }

  if (isLoadingError || (isFetched && canOpen && !canOpen(user as User))) {
    return <Navigate replace to="/unauthorized" />;
  }

  return <Outlet />;
};
