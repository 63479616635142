import styled from 'styled-components';
import { gray100 } from '../constants';

export const DatatableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .sticky {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .p-datatable-thead {
    position: sticky;
    top: 52px;
    background: ${gray100};
    z-index: 1;
  }

  .p-paginator {
    position: sticky;
    bottom: 0;
  }
`;
