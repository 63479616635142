export const gray100 = '#f5f1ee';
export const gray200 = '#f1ecea';
export const gray300 = '#d8d8d8';
export const gray350 = '#A19C9D';
export const gray375 = '#716E6F';
export const gray400 = '#595757';
export const gray700 = '#293047';
export const gray800 = '#333232';

export const green400 = '#00c6bb';
export const green500 = '#00b8ad';

export const orange500 = '#EA7100';

export const borderRadius = 6;
