export const getQueryTransformators = () => ({
  filters: {
    encode: (value: any): string => JSON.stringify(value),
    decode: (value: string): any => JSON.parse(value),
  },
  'sort[]': {
    encode: (value: string[]) => value.join(','),
    decode: (value: string) => value.split(','),
  },
});
