import React from 'react';
import styled from 'styled-components';
import { AddItemsButton } from './AddItemsButton';
import { MainMenu } from './MainMenu';

const StyledSidebar = styled.aside`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 12px;
  width: 38px;
  margin-bottom: 20px;
`;

export const Sidebar: React.FC = () => (
  <StyledSidebar>
    <AddItemsButton />
    <MainMenu />
  </StyledSidebar>
);
