import React from 'react';
import styled from 'styled-components';

export type PageErrorProps = {
  errorText: string;
};

const StyledDiv = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled.span`
  font-size: 2rem;
`;

export const PageError: React.FC<PageErrorProps> = ({ errorText }) => (
  <StyledDiv>
    <StyledText>{errorText}</StyledText>
  </StyledDiv>
);
