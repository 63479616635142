import React from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import classNames from 'classnames';

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 30px 30px 10px;
  align-items: center;
`;

const SectionHeading = styled.h3`
  font-size: 16px;
  font-weight: 400;

  &.size--xl {
    font-size: 20px;
  }
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  font-weight: 400;

  .p-button-label {
    font-weight: 400;
  }
`;

type SectionHeaderProps = {
  title: string;
  addText?: string;
  onAdd?: () => void;
  size?: 'xs' | 'l' | 'xl';
  className?: string;
};

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  addText,
  onAdd,
  children,
  size = 'l',
  className,
}) => (
  <SectionContainer className={className}>
    <SectionHeading
      className={classNames({
        'size--xl': size === 'xl',
      })}
    >
      {title}
    </SectionHeading>
    {addText && (
      <StyledButton
        onClick={onAdd}
        label={addText}
        className="p-button-text"
        type="button"
      />
    )}
    {children}
  </SectionContainer>
);
