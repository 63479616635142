import { USER_STORAGE_KEY, USER_TOKEN_EXPIRES } from '@/constants';
import { createSlice } from '@reduxjs/toolkit';
import { User } from '@timeshifter/react-sdk';
import { plainToInstance } from 'class-transformer';
import { storage } from 'helpers';
import moment from 'moment';

export type AuthState = {
  user: User | null;
  isLogged: boolean;
  tokenExpiresOn: Date | null;
};

const initialState: AuthState = {
  user: storage.get(USER_STORAGE_KEY, false)
    ? plainToInstance(User, storage.get(USER_STORAGE_KEY))
    : null,
  isLogged: !storage.get(USER_STORAGE_KEY, false),
  tokenExpiresOn: storage.get(USER_TOKEN_EXPIRES, false)
    ? moment(storage.get(USER_TOKEN_EXPIRES)).toDate()
    : null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLoginFullfilled: (
      state,
      action: { type: string; payload: { expiresOn: Date } },
    ) => {
      state.tokenExpiresOn = action.payload.expiresOn;

      storage.set(USER_TOKEN_EXPIRES, action.payload.expiresOn);
    },
    onUserFullilled: (
      state,
      action: { type: string; payload: { user: User } },
    ) => {
      state.user = action.payload.user;
      state.isLogged = true;

      storage.set(USER_STORAGE_KEY, action.payload.user);
    },
  },
});

export const { onLoginFullfilled, onUserFullilled } = authSlice.actions;

export default authSlice.reducer;
