import { gray700 } from '@/components';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as RulesIcon } from '@/svg/rules.svg';
import { ReactComponent as CustomersIcon } from '@/svg/customers.svg';
import { ReactComponent as PeopleIcon } from '@/svg/people.svg';
import { ReactComponent as WaitingListIcon } from '@/svg/wating-list.svg';
import SettingsIcon from '@/svg/settings.svg';
import { DollarSign, Flag } from 'react-feather';

const StyledNav = styled.nav`
  background: ${gray700};
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  height: 100%;
  padding-top: 10px;
`;
const StyledUl = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  li {
    margin-bottom: 0px;
    width: 100%;
    text-align: center;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  li a {
    color: #fff;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;

    &.active {
      background: rgba(0, 198, 187, 0.1);

      img,
      svg {
        stroke: #00c6bb;
      }
    }

    &:hover {
      background: rgba(0, 198, 187, 0.1);
    }
  }
`;

export const MainMenu: React.FC = () => (
  <StyledNav className="main-menu">
    <Tooltip target=".main-menu li" />
    <StyledUl className="mt-2" style={{ position: 'sticky', top: '10px' }}>
      <li data-pr-tooltip="Rules">
        <NavLink to="/rules">
          <RulesIcon />
        </NavLink>
      </li>
      <li data-pr-tooltip="Companies">
        <NavLink to="/companies">
          <CustomersIcon />
        </NavLink>
      </li>
      <li data-pr-tooltip="Users">
        <NavLink to="/users">
          <PeopleIcon />
        </NavLink>
      </li>
      <li data-pr-tooltip="Waiting list">
        <NavLink to="/invites">
          <WaitingListIcon />
        </NavLink>
      </li>
      <li data-pr-tooltip="Subscription plans">
        <NavLink to="/subscription-plans">
          <DollarSign strokeWidth={1} />
        </NavLink>
      </li>
    </StyledUl>
    <StyledUl style={{ position: 'sticky', bottom: '10px' }}>
      <li data-pr-tooltip="Countries">
        <NavLink to="/nomenclatures/countries">
          <Flag strokeWidth={1} />
        </NavLink>
      </li>
      <li data-pr-tooltip="Logout">
        <NavLink to="/logout">
          <img src={SettingsIcon} alt="Logout" />
        </NavLink>
      </li>
    </StyledUl>
  </StyledNav>
);
